module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-a4e3d3c01a/3/.yarn/berry/cache/gatsby-plugin-styled-components-npm-6.13.1-a700bfd5f0-10c0.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-b874d770c8/3/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4713c1486289c6ac93f15fad72e843a6"},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-de45ca681c/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
